<template>
  <div class="message-container">
    <div class="flex jc_b ai_c mb24">
      <tabbar @change="tabChange" :value="curTabbar" :list="tabbarList" />
      <t-btn
        @click="readAllMessage"
        w="106px"
        h="36px"
		type="text"
		v-if="list.length"
        fs="14">
        一键已读
      </t-btn>
    </div>
    <div
      v-infinite-scroll="getMessageList"
      :infinite-scroll-distance="60"
      :infinite-scroll-disabled="isDisabled"
      :key="curTabbar"
      :class="{ 'flex aj_c ai_c': !list || !list.length }"
      class="infinite-list message-main">
      <div v-show="!list || !list.length" class="nothing-box" style="margin-top: -100px;">
        <i class="not-icon" />
        <p class="not-tit">暂无内容</p>
      </div>
      <div @click="onItem(v)" v-for="v in list" :key="v.id" class="msg-item" :class="{cursor:(v.type  == 1 || v.type == 2)}">
        <div class="item-hd">
          <div class="left flex ai_c">
            <span class="l-tip mr10">系统</span>
            <el-badge :hidden="Boolean(v.read)" class="l-title" is-dot>{{ v.title }}</el-badge>
          </div>
          <span class="right">发布时间：{{ v.createdAt }}</span>
        </div>
        <div class="item-content">
          <p v-html="v.content" class="text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import message from '@/mixins/message'
import tabbar from '@/components/tabbar'

export default {
  mixins: [message],
  inject : ["headerRefresh"],
  components: {
    tabbar
  },
  data() {
    return {
      curTabbar: 0,
      tabbarList: [
        {
          label: '全部消息',
          key: 0,
        }, {
          label: '未读消息',
          key: 1
        }
      ],
      isDisabled: false,
      pages: {
        page: 1,
        prePage: 10
      },
      list: [  ]
    }
  },
  methods: {
    ...mapMutations(['SETMSGDIALOG']),
    // tabbar改变
    tabChange(val) {
      this.curTabbar = val
      this.pages.page = 1
      this.list = []
      this.isDisabled = false
    },
    // 列为已读
    readMessage(info) {
      if (info.read) return
      this.headerRefresh();
      this.$api.message.readMessage({ id: info.id })
    },
    // 跳转消息详情
    onItem(v) {
      if( v.type == 1){ 
        this.$router.push({
          name: 'messageDetail',
          params: {
            info: v
          }
        })
      }else if( v.type == 2){
        this.readMessage(v)
        this.$router.push({
          name: 'cooperation'
        })
      }
    },
    // 获取消息列表
    getMessageList() {
      const { pages, curTabbar, isDisabled } = this
      const pre = curTabbar

      if (isDisabled) return

      this.isDisabled = true
      this.$api.message.getMessageList({
        ...pages,
        type: curTabbar
      }).then(res => {
        if (res.items && res.items.length) {
          if (pre === this.curTabbar) {
            this.list = this.list.concat(res.items)
            this.pages.page = pages.page + 1
          }
          this.isDisabled = false
        } else {
          this.isDisabled = true
        }
      }).catch(() => {
        this.isDisabled = false
      })
    },
    // 一键已读
    readAllMessage() {
      this.SETMSGDIALOG({
        title: '一键已读',
        message: '确认是否一键已读',
		width:"439px",
		type:"error",
        commit: () => {
          this.$api.message.readAllMessage().then(() => {
            this.success({
				message: '一键已读成功',
				duration:1000
            })
            this.headerRefresh();
            this.tabChange(this.curTabbar)
            this.SETMSGDIALOG(null)
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .message-container {
   height: 100%;
   background: #fff;
   padding: 24px;
   border-radius:16px;
   .button-container{	   
		background: #40B9A4;
		color:#fff;
   }
    .message-main {
      width: 100%;
      height: calc(100% - 65px);      
      overflow-y: scroll;
      &::-webkit-scrollbar{width:0;}
      .msg-item {
        // cursor: pointer;
        margin-bottom: 16px;
		background: #FFFFFF;
		box-shadow: 0px 2px 13px 0px #EFF2F5;
		border-radius: 8px;
		padding:18px 16px;
        .item-hd {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 13px;
          .left {
           
            display: flex;
            align-items: center;
            .l-tip {
              padding: 4px 15px 3px;
              background: rgba($color: $primary, $alpha: .1);
              border-radius: 4px;
              font-size: 12px;
              color: $primary;
            }
            .l-title {
              width: 800px;
              font-size: 20px;
              font-weight: 500;
              color: $primary_text;
              line-height: 28px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .right {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            line-height: 22px;
            color: $tertiary_text;
          }
        }
        .item-content {
          .text {
            max-height: 56px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 28px;
            white-space: pre-line;
            overflow : hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
		}
    }
  }
</style>
