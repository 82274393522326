<template>
  <div class="tabbar-container">
    <span
      @click="onItem(v.key)"
      v-for="v in list"
      :key="v.key"
      :class="{ is_active: value === v.key }"
      class="tabbar-item">{{ v.label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      value: () => []
    },
    value: {
      type: [String, Number],
      value: null
    }
  },
  methods: {
    onItem(key) {
      if (this.value === key) return

      this.$emit('change', key)
    }
  }
}
</script>

<style lang="scss" scoped>
  .tabbar-container {
    display: flex;
    align-items: center;
    .tabbar-item {
      position: relative;
      font-size: 18px;
      color: #60707D;
      cursor: pointer;
      user-select: none;
	  line-height: 28px;	  
	  font-weight: 500;
	  padding-bottom:13px;
	  &::after {
	    content: '';
	    position: absolute;
	    width: 32px;
	    height: 4px;
	    border-radius: 2px;
	    left: 50%;
	    bottom: 0;
	    transform: translateX(-50%);
	  }
      &.is_active {
        color: $primary_title;
        font-weight: 600;
		font-size: 20px;
		&::after{
			background: $primary;
		}
      }
      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }
</style>
