<template>
  <div class="detail-container">
    <div class="detail-hd">
      <div class="left">
        <span class="l-tip mr10">消息</span>
        <div class="l-title">{{ $route.params.info.title }}</div>
      </div>
      <span class="right">发布时间：{{ $route.params.info.createdAt }}</span>
    </div>
    <div class="detail-content">
      <p v-html="$route.params.info.content" class="text" />
    </div>
  </div>
</template>

<script>
export default {
  inject : ["headerRefresh"],
  methods: {
    // 列为已读
    readMessage() {
      const { info } = this.$route.params
      console.info(info.read)
      if (info.read) return
      this.headerRefresh();
      this.$api.message.readMessage({ id: info.id });
    }
  },
  mounted() {
    this.readMessage()
  },
  beforeRouteEnter: (to, from, next) => {
    const { params } = to

    if (params.info) {
      next()
    } else {
      next('/message')
    }
  },
};
</script>

<style lang="scss" scoped>
.detail-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fff;
  padding: 24px;
  border-radius:16px;
  .detail-hd {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    .left {
      display: flex;
      align-items: center;
      .l-tip {
        padding: 6px 15px;
		line-height: 20px;
        background: rgba($color: $primary, $alpha: 0.1);
        border-radius: 8px;
        font-size: 14px;
        color: $primary;
      }
      .l-title {
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        color: $primary_text;
		font-family: PingFangSC-Semibold, PingFang SC;
      }
    }
    .right {
      font-size: 14px;
      font-weight: 400;
      color: $tertiary_text;
    }
  }
  .detail-content {
    flex: 1;
    .text {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: $primary_text;
      white-space: pre-line;
    }
  }
}
</style>